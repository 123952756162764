import { default as Cookies } from 'universal-cookie';
import { setEnv, setIsBetkwiffDomain, setIsCasinoDomain } from '../state/actions';
import { getGeoLocation } from './i18n';
import { tryToInitiatePostHog } from './posthog';
import { checkIfIsBetkwiffDomain, checkIfIsCasinoDomain, getEnv } from './url';

export let cookies: Cookies;
export const POST_HOG_ENABLED = Boolean(JSON.parse(process.env.REACT_APP_POST_HOG_ENABLED || 'false'));

const importPolyFillScrollingLibrary = async () => {
  if (!('scrollBehavior' in document.documentElement.style)) {
    await import('scroll-behavior-polyfill');
  }
};

export const initialise = async (): Promise<boolean> => {
  importPolyFillScrollingLibrary();
  await getGeoLocation();

  const isCasinoDomain = checkIfIsCasinoDomain();
  const isBetkwiffDomain = checkIfIsBetkwiffDomain();
  const env = getEnv(isBetkwiffDomain);

  setIsCasinoDomain(isCasinoDomain);
  setIsBetkwiffDomain(isBetkwiffDomain);
  setEnv(env);

  cookies = new Cookies();
  tryToInitiatePostHog();
  return true;
};
