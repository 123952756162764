import { store } from './store';
import { SET_ENV, SET_IS_BETKWIFF_DOMAIN, SET_IS_CASINO_DOMAIN, SET_USER_GEO_LOCATION } from './types';

export const setUserGeoLocation = (meta: string) => {
  store.dispatch({
    meta,
    type: SET_USER_GEO_LOCATION,
  });
};

export const setIsCasinoDomain = (meta: boolean) => {
  store.dispatch({
    meta,
    type: SET_IS_CASINO_DOMAIN,
  });
};

export const setIsBetkwiffDomain = (meta: boolean) => {
  store.dispatch({
    meta,
    type: SET_IS_BETKWIFF_DOMAIN,
  });
};

export const setEnv = (meta: string) => {
  store.dispatch({
    meta,
    type: SET_ENV,
  });
};
