import { BETKWIFF_DOMAIN, BETKWIFF_PROD, CASINO, KWIFF_DOMAIN, KWIFF_PROD } from '../constants';
import { CookieType } from '../data-types/cookies/cookiesEnums';
import { UrlSearchParamType } from '../data-types/url/urlEnums';
import { AcceptedCountries } from '../enums';
import { store } from '../state/store';
import { getCookie } from './cookies';

export const getEnvUrl = () => store.getState().env;

const tryToAddBTagSearchParams = (url: string, bTag: string | undefined) => {
  let baseUrl = url;

  if (bTag) {
    const siteId = getCookie(CookieType.SITE_ID);
    const aId = getCookie(CookieType.A_ID);
    const bannerId = getCookie(CookieType.BANNER_ID);

    baseUrl = `${baseUrl}/?${UrlSearchParamType.BTAG}=${bTag}`;

    if (siteId) {
      baseUrl = `${baseUrl}&${UrlSearchParamType.SITE_ID}=${siteId}`;
    }
    if (aId) {
      baseUrl = `${baseUrl}&${UrlSearchParamType.A_ID}=${aId}`;
    }
    if (bannerId) {
      baseUrl = `${baseUrl}&${UrlSearchParamType.BANNER_ID}=${bannerId}`;
    }
  }

  return baseUrl;
};

const tryToAddAffIdSearchParams = (url: string, bTag: string | undefined, affId: string | undefined) => {
  let baseUrl = url;

  if (bTag && affId) {
    baseUrl = `${baseUrl}&${UrlSearchParamType.AFF_ID}=${affId}`;
  } else if (!bTag && affId) {
    baseUrl = `${baseUrl}/?${UrlSearchParamType.AFF_ID}=${affId}`;
  }

  return baseUrl;
};

const tryToAddSourceIdSearchParams = (url: string, bTag: string | undefined, affId: string | undefined, sourceId: string | undefined) => {
  let baseUrl = url;

  if ((bTag || affId) && sourceId) {
    baseUrl = `${baseUrl}&${UrlSearchParamType.SOURCE_ID}=${sourceId}`;
  } else if (!bTag && !affId && sourceId) {
    baseUrl = `${baseUrl}/?${UrlSearchParamType.SOURCE_ID}=${sourceId}`;
  }

  return baseUrl;
};

const addSignupSearchParams = (url: string, otherParamsExist: boolean) => {
  let baseUrl = url;

  if (otherParamsExist) {
    baseUrl = `${baseUrl}&${UrlSearchParamType.SIGNUP}=true`;
  } else {
    baseUrl = `${baseUrl}/?${UrlSearchParamType.SIGNUP}=true`;
  }

  return baseUrl;
};

const addCasinoParameterIfNeeded = (defaultUrl: string, otherParamsExist: boolean) => {
  const { isCasinoDomain } = store.getState();

  let url = defaultUrl;
  if (!otherParamsExist && isCasinoDomain) {
    url = `${url}/?${UrlSearchParamType.CASINO_FIRST}=true`;
  } else if (otherParamsExist && isCasinoDomain) {
    url = `${url}&${UrlSearchParamType.CASINO_FIRST}=true`;
  }
  return url;
};

export const generateHomeUrl = () => {
  const bTag = getCookie(CookieType.BTAG);
  const affId = getCookie(CookieType.AFF_ID);
  const sourceId = getCookie(CookieType.SOURCE_ID);

  let url = getEnvUrl();
  url = tryToAddBTagSearchParams(url, bTag);
  url = tryToAddAffIdSearchParams(url, bTag, affId);
  url = tryToAddSourceIdSearchParams(url, bTag, affId, sourceId);

  const otherSearchParamsHaveBeenAdded = !!bTag || !!affId || !!sourceId;
  url = addCasinoParameterIfNeeded(url, otherSearchParamsHaveBeenAdded);
  return url;
};

export const generateSignUpUrl = (path = '') => {
  const bTag = getCookie(CookieType.BTAG);
  const affId = getCookie(CookieType.AFF_ID);
  const sourceId = getCookie(CookieType.SOURCE_ID);

  const env = getEnvUrl();
  let url = `${env}${path}`;
  url = tryToAddBTagSearchParams(url, bTag);
  url = tryToAddAffIdSearchParams(url, bTag, affId);
  url = tryToAddSourceIdSearchParams(url, bTag, affId, sourceId);

  const otherSearchParamsHaveBeenAdded = !!bTag || !!affId || !!sourceId;
  url = addSignupSearchParams(url, otherSearchParamsHaveBeenAdded);
  url = addCasinoParameterIfNeeded(url, true);
  return url;
};

export const generateLogInUrl = () => {
  const bTag = getCookie(CookieType.BTAG);
  const affId = getCookie(CookieType.AFF_ID);
  const sourceId = getCookie(CookieType.SOURCE_ID);

  const env = getEnvUrl();
  let url = `${env}/login`;
  url = tryToAddBTagSearchParams(url, bTag);
  url = tryToAddAffIdSearchParams(url, bTag, affId);
  url = tryToAddSourceIdSearchParams(url, bTag, affId, sourceId);

  const otherSearchParamsHaveBeenAdded = !!bTag || !!affId || !!sourceId;
  url = addCasinoParameterIfNeeded(url, otherSearchParamsHaveBeenAdded);
  return url;
};

export const checkIfIsCasinoDomain = () => {
  return window.location.hostname.includes(CASINO);
};

export const checkIfIsBetkwiffDomain = () => {
  return window.location.hostname.includes(BETKWIFF_DOMAIN);
};

export const getEnv = (isBetkwiffDomain: boolean) => {
  const { geoLocation } = store.getState();

  const fallbackEnv =
    process.env.REACT_APP_ENV && isBetkwiffDomain
      ? process.env.REACT_APP_ENV.replace(KWIFF_DOMAIN, BETKWIFF_DOMAIN)
      : process.env.REACT_APP_ENV && !isBetkwiffDomain
      ? process.env.REACT_APP_ENV
      : isBetkwiffDomain
      ? BETKWIFF_PROD
      : KWIFF_PROD;

  switch (geoLocation) {
    case AcceptedCountries.INDIA:
      if (process.env.REACT_APP_ENV_INDIA) {
        return process.env.REACT_APP_ENV_INDIA;
      }
      return fallbackEnv;
    default:
      return fallbackEnv;
  }
};
