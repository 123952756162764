export type Action = SetUserGeoLocation | SetIsCasinoDomain | SetIsBetkwiffDomain | SetEnv;

export const SET_USER_GEO_LOCATION = 'SET_USER_GEO_LOCATION';
export const SET_IS_CASINO_DOMAIN = 'SET_IS_CASINO_DOMAIN';
export const SET_IS_BETKWIFF_DOMAIN = 'SET_IS_BETKWIFF_DOMAIN';
export const SET_ENV = 'SET_ENV';

export interface TStore {
  geoLocation: string;
  isCasinoDomain: boolean;
  isBetkwiffDomain: boolean;
  env: string;
}

interface SetUserGeoLocation {
  type: typeof SET_USER_GEO_LOCATION;
  meta: string;
}

interface SetIsCasinoDomain {
  type: typeof SET_IS_CASINO_DOMAIN;
  meta: boolean;
}

interface SetIsBetkwiffDomain {
  type: typeof SET_IS_BETKWIFF_DOMAIN;
  meta: boolean;
}

interface SetEnv {
  type: typeof SET_ENV;
  meta: string;
}
