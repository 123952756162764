import { CookieType } from '../data-types/cookies/cookiesEnums';
import { UrlSearchParamType } from '../data-types/url/urlEnums';
import { getSourceIdByName } from './affiliateSource';
import { cookies } from './initialisation';

const BTAG_COOKIE_DAYS_TO_LIVE = 30;

export const computeExpirationDate = (days: number) => {
  const todayDate = new Date().getDate();
  const futureDate = new Date().setDate(todayDate + days);
  return new Date(futureDate);
};

export const getCookie = (tag: string) => {
  return cookies.get(tag) || undefined;
};

export const setCookie = (tag: string, value?: string, cookieExpirationDate?: Date) => {
  cookies.set(tag, value, { path: '/', expires: cookieExpirationDate });
};

export const setupIdsSeparatelyFromBTag = (bTag: string) => {
  const splitRegex = /[a-zA-Z]_/;
  const splittedBTag = bTag.split(splitRegex);
  const numberOfSearchParams = splittedBTag.length;

  if (numberOfSearchParams >= 2) {
    const siteId = splittedBTag[1];
    setCookie(CookieType.SITE_ID, siteId, computeExpirationDate(BTAG_COOKIE_DAYS_TO_LIVE));
  }

  if (numberOfSearchParams >= 3) {
    const bannerId = splittedBTag[2];
    setCookie(CookieType.BANNER_ID, bannerId, computeExpirationDate(BTAG_COOKIE_DAYS_TO_LIVE));
  }

  if (numberOfSearchParams >= 4) {
    const aId = splittedBTag[3];
    setCookie(CookieType.A_ID, aId, computeExpirationDate(BTAG_COOKIE_DAYS_TO_LIVE));
  }
};

export const handleUserBTagUsage = () => {
  const existingBTag = getCookie(CookieType.BTAG);
  const existingAffId = getCookie(CookieType.AFF_ID);
  const existingSourceId = getCookie(CookieType.SOURCE_ID);

  const searchParams = new URLSearchParams(window.location.search);
  const bTag = searchParams.get(UrlSearchParamType.BTAG) || undefined;
  const affId = searchParams.get(UrlSearchParamType.AFF_ID) || undefined;
  const source = searchParams.get(UrlSearchParamType.SOURCE) || undefined;
  const newSourceId = String(getSourceIdByName(source || ''));

  if (bTag && bTag !== existingBTag) {
    setCookie(CookieType.BTAG, bTag, computeExpirationDate(BTAG_COOKIE_DAYS_TO_LIVE));
    setupIdsSeparatelyFromBTag(bTag);
  }

  if (affId && affId !== existingAffId) {
    setCookie(CookieType.AFF_ID, affId, computeExpirationDate(BTAG_COOKIE_DAYS_TO_LIVE));
  }

  if (newSourceId && newSourceId !== existingSourceId) {
    setCookie(CookieType.SOURCE_ID, newSourceId, computeExpirationDate(BTAG_COOKIE_DAYS_TO_LIVE));
  }
};
