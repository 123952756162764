import { AcceptedCountries } from './enums';

export const KWIFF_DOMAIN = 'kwiff';
export const BETKWIFF_DOMAIN = 'betkwiff';

export const CASINO = 'casino';

export const KWIFF_PROD = `https://www.${KWIFF_DOMAIN}.com`;
export const BETKWIFF_PROD = `https://www.${BETKWIFF_DOMAIN}.com`;

export const CONTACT_URL = 'https://mail.google.com/mail/u/0/?view=cm&amp;fs=1&amp;tf=1&amp;source=mailto&amp;to=help@kwiff.com';
export const TERMS_URL = 'https://corporate.kwiff.com/terms-conditions/';
export const PRIVACY_URL = 'https://corporate.kwiff.com/privacy-notice';
export const PARTNERS_URL = 'https://partners.kwiff.com/';
export const BETTING_RULES_URL = 'https://corporate.kwiff.com/betting-rules/';
export const RESPONSIBLE_GAMBLING_URL = 'https://corporate.kwiff.com/responsible-gambling/';
export const GAMBLING_COMMISSION_LICENCE_URL = 'https://www.gamblingcommission.gov.uk/public-register/business/detail/44448';

export const GAMCARE_URL = 'https://www.gamcare.org.uk/';
export const GAMSTOP_URL = 'https://www.gamstop.co.uk/';
export const GAMBLE_AWARE_URL = 'https://www.begambleaware.org/';
export const VISA_URL = 'https://kwiff.com/sports/default';
export const MASTERCARD_URL = 'https://kwiff.com/sports/default';
export const ECOGRA_URL = 'https://ecogra.org/';
export const SAFECHARGE_URL = 'https://www.safecharge.com/';
export const EVOLUTION_URL = 'https://www.evolution.com/';
export const YGGDRASIL_URL = 'https://www.yggdrasilgaming.com/';
export const PLAYNGO_URL = 'https://www.playngo.com/';
export const NETENT_URL = 'https://www.netent.com/en/';
export const MICROGAMING_URL = 'https://www.microgaming.co.uk/';
export const GOVERNMENT_URL = 'https://www.gov.im/categories/business-and-industries/gambling-and-e-gaming/';

export const FACEBOOK_URL = 'https://www.facebook.com/kwiffofficial/';
export const TWITTER_URL = 'https://twitter.com/kwiffofficial';
export const INSTAGRAM_URL = 'https://www.instagram.com/kwiffofficial/';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCYvLMnhDRP19cMElXvn9xNg';

export const ISLE_OF_MAN_LICENCE_NUMBER = '017900V';

export const whiteListedCountriesMinimalCasinoContent = [
  AcceptedCountries.BRAZIL as string,
  AcceptedCountries.INDIA as string,
  AcceptedCountries.NEW_ZEALAND as string,
  AcceptedCountries.MEXICO as string,
  AcceptedCountries.COLOMBIA as string,
  AcceptedCountries.CHILE as string,
  AcceptedCountries.PERU as string,
  AcceptedCountries.ARGENTINA as string,
  AcceptedCountries.ECUADOR as string,
  AcceptedCountries.EL_SALVADOR as string,
  AcceptedCountries.PARAGUAY as string,
  AcceptedCountries.URUGUAY as string,
];
