import { AcceptedCountries } from '../enums';
import { Action, SET_ENV, SET_IS_BETKWIFF_DOMAIN, SET_IS_CASINO_DOMAIN, SET_USER_GEO_LOCATION, TStore } from './types';

export const initialState: TStore = {
  geoLocation: AcceptedCountries.UNITED_KINGDOM,
  isCasinoDomain: false,
  isBetkwiffDomain: false,
  env: '',
};

export const handler = (state = initialState, action: Action): TStore => {
  switch (action.type) {
    case SET_USER_GEO_LOCATION: {
      return { ...state, geoLocation: action.meta };
    }
    case SET_IS_CASINO_DOMAIN: {
      return { ...state, isCasinoDomain: action.meta };
    }
    case SET_IS_BETKWIFF_DOMAIN: {
      return { ...state, isBetkwiffDomain: action.meta };
    }
    case SET_ENV: {
      return { ...state, env: action.meta };
    }
    default:
      return state;
  }
};
