import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core/';
import classnames from 'classnames';
import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { POST_HOG_EVENT } from '../../data-types/posthog/posthogEnums';
import { KwiffTheme } from '../../themes/theme';
import { getSignUpUrl } from '../../utils/i18n';
import { postHogCapture } from '../../utils/posthog';

interface Props {
  theme: KwiffTheme;
  width?: number;
  renderJoinHere: boolean;
  isBetkwiffDomain: boolean;
}

interface State {
  joinButtonVisible: boolean;
}

class JoinButton extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps, State> {
  public state: State = {
    joinButtonVisible: false,
  };

  public componentDidMount = () => {
    const { renderJoinHere } = this.props;

    if (renderJoinHere) {
      const timeout = setTimeout(() => {
        this.setButtonVisibility(true);
        clearTimeout(timeout);
      }, 500);
    }
  };

  public componentWillUnmount = () => {
    this.setButtonVisibility = (_: boolean) => undefined;
  };

  private setButtonVisibility = (joinButtonVisible: boolean) => this.setState({ joinButtonVisible });

  private handleJoin = () => {
    window.open(getSignUpUrl(), '_blank');
    postHogCapture(POST_HOG_EVENT.WELCOME_JOIN, {});
  };

  public render(): React.ReactNode {
    const {
      classes,
      theme,
      width,
      renderJoinHere,
      isBetkwiffDomain,
      intl: { formatMessage },
    } = this.props;
    const { joinButtonVisible } = this.state;
    const isVerySmallScreen = width && width <= theme.breakpoints.values.xs;
    const isMobileAndBetkwiffDomain = width && width < theme.breakpoints.values.lg && isBetkwiffDomain;

    return (
      <div
        data-testid="join-button"
        id="join"
        className={classnames(classes.button, classes.join, {
          [classes.pulseAnimation]: renderJoinHere,
          [classes.bigButton]: renderJoinHere,
          [classes.invisible]: !joinButtonVisible && renderJoinHere,
          [classes.visible]: joinButtonVisible && renderJoinHere,
        })}
        style={{
          width: isVerySmallScreen ? '50px' : isMobileAndBetkwiffDomain ? '75px' : '100px',
        }}
        onClick={this.handleJoin}
      >
        <Typography
          data-testid="join-button-text"
          id="join-text"
          className={classnames(classes.buttonText, {
            [classes.bigButtonText]: renderJoinHere,
          })}
        >
          {renderJoinHere
            ? formatMessage({ id: 'join_button.join_here' }).toUpperCase()
            : formatMessage({ id: 'join_button.join' }).toUpperCase()}
        </Typography>
      </div>
    );
  }
}

const styles = (theme: KwiffTheme) => {
  return createStyles({
    button: {
      cursor: 'pointer',
      height: '36px',
      boxShadow: theme.shadows[0],
      justifyContent: 'space-evenly',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      verticalAlign: 'middle',
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        height: '54px',
      },
    },
    pulseAnimation: {
      '&:hover': {
        animation: `$pulse 1s infinite`,
        animationTimingFunction: 'linear',
      },
    },
    '@keyframes pulse': {
      '0%': { transform: 'scale(1)' },
      '50%': { transform: 'scale(1.1)' },
      '100%': { transform: 'scale(1)' },
    },
    bigButton: {
      height: '54px !important',
      width: '200px !important',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        height: '50px !important',
        width: '140px !important',
      },
    },
    buttonText: {
      textAlign: 'center',
      fontSize: '12px',
      color: theme.palette.common.white,
      fontFamily: 'visuelt',
    },
    bigButtonText: {
      fontSize: '16px',
    },
    join: {
      height: '32px',
      width: '100px',
      minWidth: 'fit-content',
      padding: `0 ${theme.spacing(0.5)}px`,
    },
    visible: {
      opacity: 1,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.complex,
        easing: theme.transitions.easing.easeIn,
      }),
    },
    invisible: {
      opacity: 0,
    },
  });
};

export default withStyles(styles, { withTheme: true })(injectIntl(JoinButton));
