import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import kwiffLogoSvg from '../assets/kwiffLogo.svg';
import { KwiffTheme, TOP_BAR_HEIGHT } from '../themes/theme';
import { generateHomeUrl } from '../utils/url';
import JoinButton from './buttons/JoinButton';
import LoginButton from './buttons/LoginButton';
import { ResizeHandler } from './utils/ResizeHandler';

interface Props {
  isBetkwiffDomain: boolean;
}

interface State {
  width: number;
}

class TopBar extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps, State> {
  public state: State = {
    width: window.innerWidth,
  };

  private setWidth = (width: number) => {
    this.setState({ width });
  };

  private handleWebAppHomeRedirect = () => {
    window.open(generateHomeUrl(), '_blank');
  };

  public render(): React.ReactNode {
    const { classes, isBetkwiffDomain } = this.props;
    const { width } = this.state;

    return (
      <div data-testid="top-bar" className={classes.root}>
        <div data-testid="top-bar-logo-container" className={classes.logoContainer} onClick={this.handleWebAppHomeRedirect}>
          <img data-testid="top-bar-logo" id="kwiff-logo" className={classes.icon} src={kwiffLogoSvg} alt="" />
          <Typography data-testid="top-bar-kwiff-text" className={classes.logo} variant="h6" color="inherit">
            <FormattedMessage id={isBetkwiffDomain ? 'common.betkwiff.logo' : 'common.logo'} />
          </Typography>
        </div>
        <div data-testid="top-bar-login-signup-buttons" className={classes.buttonsContainer}>
          <JoinButton width={width} renderJoinHere={false} isBetkwiffDomain={isBetkwiffDomain} />
          <LoginButton width={width} isBetkwiffDomain={isBetkwiffDomain} />
        </div>
        <ResizeHandler onWidthChange={this.setWidth} />
      </div>
    );
  }
}
/* Component Styling */
const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: `${TOP_BAR_HEIGHT}px`,
      width: '100%',
      boxShadow: theme.shadows[0],
      position: 'sticky',
    },
    logoContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridColumnGap: theme.spacing(1.5),
      color: theme.palette.common.white,
      alignItems: 'center',
      justifyContent: 'start',
      marginLeft: theme.spacing(1.5),
    },
    icon: {
      height: '30px',
      width: '30px',
    },
    logo: {
      display: 'block',
      fontSize: '24px',
      fontFamily: 'visuelt, sans-serif',
    },
    buttonsContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridColumnGap: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  });
};
export default withStyles(styles, { withTheme: true })(injectIntl(TopBar));
