import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Slide, Typography } from '@material-ui/core/';
import classnames from 'classnames';
import * as React from 'react';
import { default as ReactDiv100vh } from 'react-div-100vh';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import kwiffLogoSvg from '../../assets/kwiffLogo.svg';
import JourneyInformation2021 from '../../components/2021/JourneyInformation2021';
import KwiffDetails2021 from '../../components/2021/KwiffDetails2021';
import Partners2021 from '../../components/2021/Partners2021';
import Socials2021 from '../../components/2021/Socials2021';
import SurpriseBetInformation2021 from '../../components/2021/SurpriseBetInformation2021';
import Terms2021 from '../../components/2021/Terms2021';
import CookiesConsentSnackbar from '../../components/snackbars/CookiesConsentSnackbar';
import TopBar from '../../components/TopBar';
import Divider from '../../components/utils/Divider';
import { Layout, ResizeHandler } from '../../components/utils/ResizeHandler';
import ScrollBar from '../../components/utils/ScrollBar';
import { BETKWIFF_PROD, GAMBLING_COMMISSION_LICENCE_URL, GOVERNMENT_URL, ISLE_OF_MAN_LICENCE_NUMBER } from '../../constants';
import { CookieType } from '../../data-types/cookies/cookiesEnums';
import { BANNERNOW_HEIGHT_RATIO } from '../../enums';
import { KwiffTheme, MAX_MEDIUM_WIDTH, TOP_BAR_HEIGHT } from '../../themes/theme';
import { getBannerNowSource } from '../../utils/banner';
import { getCookie, handleUserBTagUsage, setCookie } from '../../utils/cookies';
import { noHorizontalScrolling } from '../../utils/scrollbar';

interface Props {
  theme: KwiffTheme;
  isCasinoDomain: boolean;
  isBetkwiffDomain: boolean;
}

interface State {
  layout: Layout;
  cookiesConsentOpen: boolean;
  bannernowSrc: string;
}

class AffiliateLandingPage2021 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps, State> {
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  public state: State = {
    layout: Layout.MOBILE,
    cookiesConsentOpen: getCookie(CookieType.AFFILIATE_COOKIE_ACCEPTANCE) !== '1',
    bannernowSrc: '',
  };

  public componentDidMount = () => {
    handleUserBTagUsage();
    this.displayBannernowASAP();
  };

  public componentWillUnmount = () => {
    this.setLayout = (_: Layout) => undefined;
    this.setBannernowSrc = (_: string, __: () => void) => undefined;
  };

  private openLicenceUrl = () => {
    const { isBetkwiffDomain } = this.props;

    const url = isBetkwiffDomain ? GOVERNMENT_URL : GAMBLING_COMMISSION_LICENCE_URL;
    window.open(url, '_blank');
  };

  private displayBannernowASAP = () => {
    const timeout = setTimeout(() => {
      const { isCasinoDomain } = this.props;
      const { layout } = this.state;
      const isDesktop = layout === Layout.DESKTOP;

      this.setBannernowSrc(getBannerNowSource(isDesktop, isCasinoDomain), () => clearTimeout(timeout));
    }, 0);
  };

  private openBetkwiff = () => {
    window.open(BETKWIFF_PROD, '_blank');
  };

  private closeConsentDialog = (accept: boolean) => {
    setCookie('affiliateCookieAcceptance', accept ? '1' : '0');
    this.setState({ cookiesConsentOpen: false });
  };

  private scrollToTermsAndConditions = () => {
    if (this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  private generateLinkForLicenceNumber = () => {
    const { classes } = this.props;

    return (
      <span className={classes.link} onClick={this.openLicenceUrl}>
        {ISLE_OF_MAN_LICENCE_NUMBER}
      </span>
    );
  };

  private setLayout = (layout: Layout) => {
    this.setState({ layout });
  };

  private setBannernowSrc = (bannernowSrc: string, callback: () => void) => this.setState({ bannernowSrc }, callback);

  public render(): React.ReactNode {
    const {
      classes,
      isCasinoDomain,
      isBetkwiffDomain,
      intl: { formatMessage },
    } = this.props;
    const { layout, cookiesConsentOpen, bannernowSrc } = this.state;
    const isDesktop = layout === Layout.DESKTOP;

    return (
      <Slide direction="left" in={true} mountOnEnter={true} unmountOnExit={true} timeout={this.props.theme.transitions.duration.short}>
        <ReactDiv100vh>
          <div data-testid="affiliate-landing-page" className={classes.root}>
            <TopBar isBetkwiffDomain={isBetkwiffDomain} />
            <ScrollBar classes={classes.scrollContainer} options={noHorizontalScrolling}>
              <div data-testid="affiliate-landing-page-container" className={classes.container}>
                <div
                  className={classnames(classes.grid, {
                    [classes.desktopView]: isDesktop,
                  })}
                >
                  <iframe
                    src={bannernowSrc}
                    style={{ border: 'none' }}
                    frameBorder={0}
                    scrolling="no"
                    title="bannernow"
                    className={classes.fullWidth}
                  />
                  <div
                    className={classnames({
                      [classes.overText]: isDesktop,
                    })}
                  >
                    <SurpriseBetInformation2021
                      onScrollToTermsAndConditions={this.scrollToTermsAndConditions}
                      isCasinoDomain={isCasinoDomain}
                      isBetkwiffDomain={isBetkwiffDomain}
                    />
                  </div>
                </div>

                <JourneyInformation2021 isCasinoDomain={isCasinoDomain} />
                <Divider isBetkwiffDomain={isBetkwiffDomain} />
                <div className={classes.termsContainer}>
                  <div ref={this.ref} />
                  <Terms2021 isCasinoDomain={isCasinoDomain} isBetkwiffDomain={isBetkwiffDomain} />
                </div>
                <Divider isBetkwiffDomain={isBetkwiffDomain} />
                <div className={classes.betkwiffPresentation}>
                  <div className={classes.logoContainer} onClick={this.openBetkwiff}>
                    <img className={classes.icon} src={kwiffLogoSvg} alt="" />
                    <Typography className={classes.logo}>
                      <FormattedMessage id={isBetkwiffDomain ? 'common.betkwiff.logo' : 'common.logo'} />
                    </Typography>
                  </div>
                  <div>
                    <KwiffDetails2021 isBetkwiffDomain={isBetkwiffDomain} />
                    <Divider isBetkwiffDomain={isBetkwiffDomain} />
                    {isBetkwiffDomain ? (
                      <Typography
                        data-testid="affiliate-landing-page-licence"
                        className={classnames(classes.content, classes.cursorDefault)}
                        variant="body1"
                      >
                        {formatMessage(
                          { id: 'responsible_gaming_banner.body' },
                          {
                            seriesNumber: this.generateLinkForLicenceNumber(),
                          },
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        data-testid="affiliate-landing-page-licence"
                        onClick={this.openLicenceUrl}
                        className={classes.content}
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'responsible_gaming_banner.body' }) }}
                      />
                    )}
                  </div>
                </div>
                <Divider isBetkwiffDomain={isBetkwiffDomain} />
                <Partners2021 isBetkwiffDomain={isBetkwiffDomain} />
                <Divider isBetkwiffDomain={isBetkwiffDomain} />
                <div className={classes.footer}>
                  <Typography data-testid="affiliate-landing-page-copyright" className={classes.copyright}>
                    <FormattedMessage id="copyright" />
                  </Typography>
                  <Socials2021 />
                </div>
              </div>
            </ScrollBar>
            {cookiesConsentOpen && <CookiesConsentSnackbar onClose={this.closeConsentDialog} />}
            <ResizeHandler onLayoutChanged={this.setLayout} />
          </div>
        </ReactDiv100vh>
      </Slide>
    );
  }
}

/* Component Styling */
const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100vw',
      overflow: 'hidden',
      cursor: 'default',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    container: {
      width: '100%',
      backgroundColor: theme.custom && theme.custom.darkGray,
    },
    banner: {
      width: '100%',
    },
    content: {
      fontSize: '11px',
      color: theme.palette.common.white,
      textAlign: 'center',
      justifySelf: 'center',
      maxWidth: '90%',
      cursor: 'pointer',
      margin: `${theme.spacing(5)}px auto`,
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        fontSize: '16px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        fontSize: '16px',
        margin: '0 auto',
      },
    },
    copyright: {
      fontSize: '11px',
      color: theme.palette.common.white,
      textAlign: 'center',
      paddingBottom: theme.spacing(2.5),
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        fontSize: '16px',
      },
    },
    backgroundedImage: {
      width: '100vw',
      height: '40vh',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        height: '50vh',
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        height: '80vh',
      },
      [theme.breakpoints.up(MAX_MEDIUM_WIDTH)]: {
        height: '90vh',
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
      },
    },
    casinoBackground: {
      backgroundPosition: 'center',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        height: '50vh',
      },
    },
    grid: {
      display: 'grid',
    },
    desktopView: {
      position: 'relative',
    },
    overText: {
      position: 'absolute',
      left: '5%',
      bottom: '10%',
    },
    termsContainer: {
      width: '100%',
      justifySelf: 'center',
      color: theme.palette.common.white,
    },
    betkwiffPresentation: {
      width: '90%',
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      justifySelf: 'center',
      margin: `${theme.spacing(5)}px 0`,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        width: '100%',
        gridTemplateColumns: '1fr',
        margin: 0,
      },
    },
    logoContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridColumnGap: theme.spacing(1),
      color: theme.palette.common.white,
      alignItems: 'center',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        display: 'none',
      },
    },
    icon: {
      height: '65px',
      width: '65px',
      justifySelf: 'end',
      cursor: 'pointer',
      [theme.breakpoints.up(MAX_MEDIUM_WIDTH)]: {
        height: '80px',
        width: '80px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        height: '100px',
        width: '100px',
      },
    },
    logo: {
      display: 'block',
      fontSize: '60px',
      cursor: 'pointer',
      [theme.breakpoints.up(MAX_MEDIUM_WIDTH)]: {
        fontSize: '75px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        fontSize: '95px',
      },
    },
    footer: {
      position: 'relative',
      padding: `${theme.spacing(2)}px 0`,
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    cursorDefault: {
      cursor: 'default',
    },
    scrollContainer: {
      height: '100%',
      width: '100vw',
    },
    fullWidth: {
      width: `100vw`,
      height: `calc(${BANNERNOW_HEIGHT_RATIO.DESKTOP} * 100vw)`,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        height: BANNERNOW_HEIGHT_RATIO.MOBILE,
      },
    },
  });
};

export default withStyles(styles, { withTheme: true })(injectIntl(AffiliateLandingPage2021));
